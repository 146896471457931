// import React, { Component } from 'react';
// import { loginUser } from '../../Actions/authActions';
// import { connect } from 'react-redux';
// import './LoginStyle.css';
// import { Link, RouteComponentProps } from 'react-router-dom';
// import ButtonSpinner from '../../Common/ButtonSpinner';
// import { authTranslations } from '../../Translations/AuthTranslations'
// import { AuthReducer, Errors, ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
// import { WithTranslation } from 'react-i18next';
// import { StaticContext } from 'react-router';
// import { LoginPayload } from '../../Interfaces/PayloadsAndResponses/Auth';

// export type LoginProps = {
//   auth: AuthReducer;
//   i18n: WithTranslation | null;
//   errors: Errors;
//   loginUser: (userData: LoginPayload) => void;
// } & RouteComponentProps<{}, StaticContext, { resetMessage: string, from: { hash: string, pathname: string, search: string, state: undefined | unknown } }>;

// type LoginState = {
//   email: string,
//   password: string,
//   isChecked: boolean,
//   errors: string,
//   loading: boolean,
//   resetMessage: string,
//   remember: boolean,
//   isPasswordShow: boolean
// }

// class Login extends Component<LoginProps, LoginState> {
//   state = {
//     email: '',
//     password: '',
//     isChecked: false,
//     errors: '',
//     loading: false,
//     resetMessage: '',
//     remember: false,
//     isPasswordShow: false
//   }

//   componentDidMount() {
//     authTranslations();
//     if (localStorage.getItem('remember')) {
//       let remember = localStorage.getItem('remember') as unknown as LoginPayload;
//       this.setState({ email: remember.email ?? '', password: remember.password ?? '', remember: true });
//     }
//     let document = window.document;
//     document.title = 'Chowmill';
//     var i = Math.floor((Math.random() * 9) + 1);
//     if (document?.getElementById('banner')?.style) {
//       document.getElementById('banner')!.style.backgroundImage = 'url(imgs/' + i + '.jpg)';
//     }
//     if (localStorage.getItem('headers')) {
//       this.redirectUser();
//     }

//   }
//   componentWillMount() {
//     if (this.props.location.search.split('=')[1]) {
//       localStorage.setItem('view_required_page', this.props.location.search.split('=')[1]);
//     } else if (localStorage.getItem('view_required_page')) {
//       localStorage.removeItem('view_required_page');
//     }
//     if (this.props.history.location.state) {
//       this.setState({ resetMessage: this.props.history.location.state.resetMessage });
//     }
//   }
//   componentWillReceiveProps(nextProps: LoginProps) {

//     if (nextProps.auth.isAuthenticated) {
//       this.redirectUser();
//     }
//     if (nextProps.errors) {
//       this.setState({ errors: nextProps.errors.errors });
//     }
//     this.setState({ loading: false });
//   }
//   redirectUser() {
//     if (this.props.history?.location?.state?.from) {
//       this.props.history.push(`${this.props.history?.location?.state?.from.pathname}`);
//     } else {
//       this.props.history.push('/dashboard/restaurant');
//     }
//   }
//   shouldComponentUpdate() {
//     return true;
//   }
//   onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
//     this.setState({ ...this.state, [e.target.name]: value })
//   }
//   handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     this.setState({ ...this.state, [e.target.name]: e.target.value })
//   }
//   onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
//     e.preventDefault();
//     this.setState({ loading: true });
//     const userData: LoginPayload = {
//       email: this.state.email,
//       password: this.state.password,
//       vendor: true
//     };
//     this.props.loginUser(userData);
//     if (this.state.remember) {
//       userData.remember = true;
//       localStorage.setItem('remember', JSON.stringify(userData));
//     } else {
//       localStorage.removeItem('remember');
//     }
//   }
//   togglePasswordVisibility = () => {
//     const { isPasswordShow } = this.state;
//     this.setState({ isPasswordShow: !isPasswordShow })
//   }
//   render() {
//     const { isPasswordShow } = this.state;
//     const { i18n } = this.props;
//     return (
//       <div>
//         <div className='container-scroller'>
//           <div className='container-fluid page-body-wrapper full-page-wrapper'>
//             <div className='content-wrapper d-flex align-items-stretch auth auth-img-bg'>
//               <div className='row flex-grow'>
//                 <div className='col-lg-6 d-flex align-items-center justify-content-center'>
//                   <div className='auth-form-transparent text-left p-3'>
//                     <div className='brand-logo'>
//                       <img src='./imgs/chowmill-logo-full.png' className='mw-100' alt='logo' />
//                     </div>
//                     {this.state.errors ?
//                       <div className='alert alert-danger' role='alert' >
//                         {this.state.errors}
//                       </div>
//                       : ''}
//                     {this.state.resetMessage && this.state.errors === '' ?
//                       <div className='alert alert-success login-alert' role='alert'>
//                         {i18n && i18n.t('Your password has been reset. Please login below using your new password.')}
//                       </div>
//                       : ''}
//                     <h4> {i18n && i18n.t('Login as a Vendor')}</h4>
//                     <h6 className='font-weight-light'>  {i18n && i18n.t('Not currently registered?')}
//                       <a href='mailto:support@chowmill.com' className='text-primary'> {i18n && i18n.t('Email us')}</a>
//                     </h6>
//                     <form className='pt-3' onSubmit={this.onSubmit}>
//                       <div className='mb-4'>
//                         <label htmlFor='exampleInputEmail'> {i18n && i18n.t('Email address')}</label>
//                         <div className='input-group'>
//                           <div className='input-group-prepend bg-transparent'>
//                             <span className='input-group-text bg-transparent border-right-0'>
//                               <i className='mdi mdi-account-outline text-primary'></i>
//                             </span>
//                           </div>
//                           <input type='email' id='inputEmail' className='form-control form-control-lg border-left-0 height-46' name='email' value={this.state.email} onChange={this.handleChange} autoComplete='on' required autoFocus placeholder='username' />
//                         </div>
//                       </div>
//                       <div className='mb-4'>
//                         <label htmlFor='exampleInputPassword'> {i18n && i18n.t('Password')}</label>
//                         <div className='input-group'>
//                           <div className='input-group-prepend bg-transparent'>
//                             <span className='input-group-text bg-transparent border-right-0 text-primary'>
//                               <i className='mdi mdi-lock-outline text-primary'></i>
//                             </span>
//                           </div>
//                           <input type={this.state.isPasswordShow ? 'text' : 'password'}
//                             id='inputPassword'
//                             className='form-control form-control-lg border-left-0 height-46'
//                             name='password'
//                             value={this.state.password}
//                             onChange={this.handleChange}
//                             autoComplete='current-password'
//                             required placeholder='password'

//                           />
//                           <div className={'password-icon'} onClick={this.togglePasswordVisibility}>
//                             {isPasswordShow ?
//                               <span>
//                                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M150.7 92.77C195 58.27 251.8 32 320 32C400.8 32 465.5 68.84 512.6 112.6C559.4 156 590.7 207.1 605.5 243.7C608.8 251.6 608.8 260.4 605.5 268.3C592.1 300.6 565.2 346.1 525.6 386.7L630.8 469.1C641.2 477.3 643.1 492.4 634.9 502.8C626.7 513.2 611.6 515.1 601.2 506.9L9.196 42.89C-1.236 34.71-3.065 19.63 5.112 9.196C13.29-1.236 28.37-3.065 38.81 5.112L150.7 92.77zM223.1 149.5L313.4 220.3C317.6 211.8 320 202.2 320 191.1C320 180.5 316.1 169.7 311.6 160.4C314.4 160.1 317.2 159.1 320 159.1C373 159.1 416 202.1 416 255.1C416 269.7 413.1 282.7 407.1 294.5L446.6 324.7C457.7 304.3 464 280.9 464 255.1C464 176.5 399.5 111.1 320 111.1C282.7 111.1 248.6 126.2 223.1 149.5zM320 480C239.2 480 174.5 443.2 127.4 399.4C80.62 355.1 49.34 304 34.46 268.3C31.18 260.4 31.18 251.6 34.46 243.7C44 220.8 60.29 191.2 83.09 161.5L177.4 235.8C176.5 242.4 176 249.1 176 255.1C176 335.5 240.5 400 320 400C338.7 400 356.6 396.4 373 389.9L446.2 447.5C409.9 467.1 367.8 480 320 480H320z" /></svg>
//                               </span>
//                               :
//                               <span>
//                                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M279.6 160.4C282.4 160.1 285.2 160 288 160C341 160 384 202.1 384 256C384 309 341 352 288 352C234.1 352 192 309 192 256C192 253.2 192.1 250.4 192.4 247.6C201.7 252.1 212.5 256 224 256C259.3 256 288 227.3 288 192C288 180.5 284.1 169.7 279.6 160.4zM480.6 112.6C527.4 156 558.7 207.1 573.5 243.7C576.8 251.6 576.8 260.4 573.5 268.3C558.7 304 527.4 355.1 480.6 399.4C433.5 443.2 368.8 480 288 480C207.2 480 142.5 443.2 95.42 399.4C48.62 355.1 17.34 304 2.461 268.3C-.8205 260.4-.8205 251.6 2.461 243.7C17.34 207.1 48.62 156 95.42 112.6C142.5 68.84 207.2 32 288 32C368.8 32 433.5 68.84 480.6 112.6V112.6zM288 112C208.5 112 144 176.5 144 256C144 335.5 208.5 400 288 400C367.5 400 432 335.5 432 256C432 176.5 367.5 112 288 112z" /></svg>
//                               </span>
//                             }
//                           </div>
//                         </div>
//                       </div>
//                       <div className='my-2 d-flex justify-content-between align-items-center'>
//                         <div className='form-check'>
//                           <label className='form-check-label text-muted'>
//                             <input type='checkbox' className='form-check-input' name='remember' checked={this.state.remember} onChange={this.onChange} />
//                             {i18n && i18n.t('Keep me signed in')}
//                             <i className='input-helper'></i>
//                           </label>
//                         </div>
//                         <React.Fragment>
//                           <Link className='auth-link text-black' to='/forgot_password' > {i18n && i18n.t('Forgot Password')}</Link>
//                         </React.Fragment>
//                       </div>
//                       <div className='my-3'>
//                         <button className='btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn' type='submit'>
//                           {this.state.loading ? <ButtonSpinner /> : <b>{i18n && i18n.t('Login')}</b>}
//                         </button>
//                       </div>
//                     </form>
//                   </div>
//                 </div>
//                 <div className='col-md-6 d-none d-lg-block login_banner' id='banner'>
//                   <p className='text-white font-weight-medium text-center flex-grow align-self-end copy-rights'> {i18n && (i18n.t('© Copyright') + new Date().getFullYear() + i18n.t('Chowmill'))} </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }


// const mapStateToProps = (state: ReduxRootState) => ({
//   auth: state.auth,
//   errors: state.errors,
//   i18n: state.i18n.i18n
// });

// export default connect(mapStateToProps, { loginUser })(Login);


import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../Actions/authActions';
import './LoginStyle.css';
import { Link, useParams, useHistory } from 'react-router-dom';
import ButtonSpinner from '../../Common/ButtonSpinner';
import { authTranslations } from '../../Translations/AuthTranslations'
import { AuthReducer, Errors, ReduxRootState } from '../../Interfaces/ReduxInterface/ReduxRootState';
import { LoginPayload } from '../../Interfaces/PayloadsAndResponses/Auth';
// import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import isEmpty from '../../Utilities/isEmpty';


const Login = () => {
 
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [stateErrors, setStateErrors] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [remember, setRemember] = useState<boolean>(false);
  const [isPasswordShow, setIsPasswordShow] = useState<boolean>(false);

  const dispatch = useDispatch<any>();
  const auth = useSelector<ReduxRootState, AuthReducer>(state => state?.auth);
  const i18n = useSelector((state: ReduxRootState) => state?.i18n?.i18n);
  
  const errors = useSelector<ReduxRootState, Errors>(state => state?.errors);
  const { resetMessage, from } = useParams<any>();
  const history = useHistory();

  useEffect(() => {
    authTranslations();
    if (localStorage.getItem('remember')) {
      const rememberData = JSON?.parse(localStorage?.getItem('remember') as string);
      setEmail(rememberData?.email ?? '');
      setPassword(rememberData?.password ?? '');
      setRemember(true);
    }
    document.title = 'Chowmill';
    const i = Math.floor((Math.random() * 9) + 1);
    if (document?.getElementById('banner')?.style) {
      document.getElementById('banner')!.style.backgroundImage = `url(imgs/${i}.jpg)`;
    }
    if (localStorage.getItem('headers')) {
      redirectUser();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (auth?.isAuthenticated) {
      redirectUser();
    }
    if (errors) {
      if (typeof (errors.errors) === "string") {
        setStateErrors(errors?.errors);
      } else if(localStorage.getItem('headers')) {
        setStateErrors(errors?.errors?.errors?.[0]);
      }else{
        setStateErrors('');
      }
    }
    setLoading(false);
    // eslint-disable-next-line
  }, [auth.isAuthenticated, errors]);

  const redirectUser = () => {
    const profileCompleteStatus = JSON.parse(localStorage.getItem('profile-complete') || '{}')
    if (isEmpty(profileCompleteStatus)) {
      if (from) {
        history.push(`${from.pathname}`);
      } else {
        history.push('/dashboard/restaurant');
      }
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value: any = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    if (e.target.name === 'email') {
      setEmail(value);
    } else if (e.target.name === 'password') {
      setPassword(value);
    } else if (e.target.name === 'remember') {
      setRemember(value);
    }
  };

const redirectToMobileApp = () => {
  window.open('chowmillRestaurant://app/LogIn', '_blank')
  };

  // const handleLoginSuccess = (response: any) => {
  //   const googleToken = response.credential
  //   console.log('Google Login Success, ID Token:', googleToken);

  //   if (googleToken) {
  //     const googleAuthenticatedUser = {
  //       google_token: googleToken,
  //       vendor: true
  //     }
  //     dispatch(loginUser(googleAuthenticatedUser));

  //   }
  // };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const userData: LoginPayload = {
      email: email,
      password: password,
      vendor: true
    };
    dispatch(loginUser(userData));
    if (remember) {
      userData.remember = true;
      localStorage.setItem('remember', JSON.stringify(userData));
    } else {
      localStorage.removeItem('remember');
    }
  }

  const togglePasswordVisibility = () => {
    setIsPasswordShow(!isPasswordShow);
  };

    return (
      <div>
        <div className='container-scroller'>
          <div className='container-fluid page-body-wrapper full-page-wrapper'>
            <div className='content-wrapper d-flex align-items-stretch auth auth-img-bg'>
              <div className='row flex-grow'>
                <div className='col-lg-6 d-flex align-items-center justify-content-center'>
                  <div className='auth-form-transparent text-left p-3 google-auto-signin'>
                    <div className='brand-logo'>
                      <img src='./imgs/chowmill-logo-full.png' className='mw-100' alt='logo' />
                    </div>
                    {stateErrors &&
                      <div className='alert alert-danger' role='alert' >
                        {stateErrors}
                      </div>
                    }
                    {resetMessage && stateErrors === '' ?
                      <div className='alert alert-success login-alert' role='alert'>
                        {i18n && i18n.t('Your password has been reset. Please login below using your new password.')}
                      </div>
                      : ''}
                    <h4> {i18n && i18n.t('Login as a Vendor')}</h4>
                    <h6 className='font-weight-light'>  {i18n && i18n.t('Not currently registered?')}
                      <a href='mailto:support@chowmill.com' className='text-primary'> {i18n && i18n.t('Email us')}</a>
                    </h6>
                    <form className='pt-3' onSubmit={onSubmit}>
                      <div className='mb-4'>
                        <label htmlFor='exampleInputEmail'> {i18n && i18n.t('Email address')}</label>
                        <div className='input-group'>
                          <div className='input-group-prepend bg-transparent'>
                            <span className='input-group-text bg-transparent border-right-0'>
                              <i className='mdi mdi-account-outline text-primary'></i>
                            </span>
                          </div>
                          <input type='email' id='inputEmail' className='form-control form-control-lg border-left-0 height-46' name='email' value={email} onChange={onChange} autoComplete='on' required autoFocus placeholder='username' />
                        </div>
                      </div>
                      <div className='mb-4'>
                        <label htmlFor='exampleInputPassword'> {i18n && i18n.t('Password')}</label>
                        <div className='input-group'>
                          <div className='input-group-prepend bg-transparent'>
                            <span className='input-group-text bg-transparent border-right-0 text-primary'>
                              <i className='mdi mdi-lock-outline text-primary'></i>
                            </span>
                          </div>
                          <input type={isPasswordShow ? 'text' : 'password'}
                            id='inputPassword'
                            className='form-control form-control-lg border-left-0 height-46'
                            name='password'
                            value={password}
                            onChange={onChange}
                            autoComplete='current-password'
                            required placeholder='password'

                          />
                          <div className={'password-icon'} onClick={togglePasswordVisibility}>
                            {isPasswordShow ?
                              <span>
                                <svg xmlns="http:www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M150.7 92.77C195 58.27 251.8 32 320 32C400.8 32 465.5 68.84 512.6 112.6C559.4 156 590.7 207.1 605.5 243.7C608.8 251.6 608.8 260.4 605.5 268.3C592.1 300.6 565.2 346.1 525.6 386.7L630.8 469.1C641.2 477.3 643.1 492.4 634.9 502.8C626.7 513.2 611.6 515.1 601.2 506.9L9.196 42.89C-1.236 34.71-3.065 19.63 5.112 9.196C13.29-1.236 28.37-3.065 38.81 5.112L150.7 92.77zM223.1 149.5L313.4 220.3C317.6 211.8 320 202.2 320 191.1C320 180.5 316.1 169.7 311.6 160.4C314.4 160.1 317.2 159.1 320 159.1C373 159.1 416 202.1 416 255.1C416 269.7 413.1 282.7 407.1 294.5L446.6 324.7C457.7 304.3 464 280.9 464 255.1C464 176.5 399.5 111.1 320 111.1C282.7 111.1 248.6 126.2 223.1 149.5zM320 480C239.2 480 174.5 443.2 127.4 399.4C80.62 355.1 49.34 304 34.46 268.3C31.18 260.4 31.18 251.6 34.46 243.7C44 220.8 60.29 191.2 83.09 161.5L177.4 235.8C176.5 242.4 176 249.1 176 255.1C176 335.5 240.5 400 320 400C338.7 400 356.6 396.4 373 389.9L446.2 447.5C409.9 467.1 367.8 480 320 480H320z" /></svg>
                              </span>
                              :
                              <span>
                                <svg xmlns="http:www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M279.6 160.4C282.4 160.1 285.2 160 288 160C341 160 384 202.1 384 256C384 309 341 352 288 352C234.1 352 192 309 192 256C192 253.2 192.1 250.4 192.4 247.6C201.7 252.1 212.5 256 224 256C259.3 256 288 227.3 288 192C288 180.5 284.1 169.7 279.6 160.4zM480.6 112.6C527.4 156 558.7 207.1 573.5 243.7C576.8 251.6 576.8 260.4 573.5 268.3C558.7 304 527.4 355.1 480.6 399.4C433.5 443.2 368.8 480 288 480C207.2 480 142.5 443.2 95.42 399.4C48.62 355.1 17.34 304 2.461 268.3C-.8205 260.4-.8205 251.6 2.461 243.7C17.34 207.1 48.62 156 95.42 112.6C142.5 68.84 207.2 32 288 32C368.8 32 433.5 68.84 480.6 112.6V112.6zM288 112C208.5 112 144 176.5 144 256C144 335.5 208.5 400 288 400C367.5 400 432 335.5 432 256C432 176.5 367.5 112 288 112z" /></svg>
                              </span>
                            }
                          </div>
                        </div>
                      </div>
                      <div className='my-2 d-flex justify-content-between align-items-center'>
                        <div className='form-check'>
                          <label className='form-check-label text-muted'>
                            <input type='checkbox' className='form-check-input' name='remember' checked={remember} onChange={onChange} />
                            {i18n && i18n.t('Keep me signed in')}
                            <i className='input-helper'></i>
                          </label>
                        </div>
                        <React.Fragment>
                          <Link className='auth-link text-black' to='/forgot_password' > {i18n && i18n.t('Forgot Password')}</Link>
                        </React.Fragment>
                      </div>
                      <div className='my-3'>
                        <button className='btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn' type='submit'>
                          {loading ? <ButtonSpinner /> : <b>{i18n && i18n.t('Login')}</b>}
                        </button>
                      </div>
                      {/* Removed Temporarily */}
                      {/* <div className="w-100 text-center">
                        <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_SIGNIN_CLIENT_ID}`}>
                          <div className="d-flex justify-content-center">
                            <GoogleLogin
                              onSuccess={handleLoginSuccess}
                              theme="outline"
                              type="standard"
                              size="large"
                              text="continue_with"
                              width={364}
                              containerProps={{
                                className: "google-login-container w-100",
                              }}
                            />
                          </div>
                        </GoogleOAuthProvider>
                      </div> */}
                      <div className='container d-flex justify-content-center text-black cursor-pointer' onClick={redirectToMobileApp}>
                        {localStorage.getItem('isMobile') === 'true' && 'Go Back To Mobile'}
                      </div>
                    </form>
                  </div>
                </div>
                <div className='col-md-6 d-none d-lg-block login_banner' id='banner'>
                  <p className='text-white font-weight-medium text-center flex-grow align-self-end copy-rights'> {i18n && (i18n.t('© Copyright') + new Date().getFullYear() + i18n.t('Chowmill'))} </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

export default Login